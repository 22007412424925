

import React, {useState, useEffect} from 'react';
import Styles from "./Login.module.scss";
//import { IoCalendarSharp } from 'react-icons/io5';
import {IUserInfo} from "../../models/user";

import {Utils} from "../../helpers/utils";
import CONFIG from "../../config";

const utils = new Utils();



interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo,
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>
    //handleAdd: (e : React.FormEvent) => void;
}





export const Login = ({userAuth, setUserAuth, userInfo, setUserInfo}:Props) => {

    const [inputPhone, setInputPhone] = useState<string>("")
    const [inputCode, setInputCode] = useState<string>("")
    const [isPhone, setIsPhone] = useState<boolean>(false)

    useEffect( () => {
        setInputPhone( localStorage.getItem('inputPhone') || "");
    }, [])

    const auth_btn = async () => {

        const pattern = /^\d{10}$/;
        if ( !pattern.test(inputPhone) ) {
            alert('не віриний номер');
            return;
        }

        localStorage.setItem('inputPhone', inputPhone)

        //console.log('click', inputPhone)

        let req = {
            phone: `38${inputPhone}` //add UA code
        }

        let req_res = await utils.requestPost(`${CONFIG.API}/auth`, req);
        if (req_res)
            setIsPhone(true);

    }

    const auth_code_btn = async () => {
        const pattern = /^\d{5}$/;
        if ( !pattern.test(inputCode) ) {
            alert('не віриний код');
            return;
        }

        //localStorage.setItem('inputPhone', inputPhone)

        //console.log('click', inputPhone)

        let req = {
            phone: `38${inputPhone}`,
            sms_code: inputCode //add UA code
        }
        let req_res = await utils.requestPost(`${CONFIG.API}/authcheck`, req);

        if (req_res.message.authorized){
            setUserAuth(true)
            //console.log(req_res)
            setUserInfo( {authDate : Date.now(), authToken: req_res.message.authToken, phone: `38${inputPhone}`, name: "", city: ""} );
            localStorage.setItem('authToken', req_res.message.authToken)
            localStorage.setItem('authDate', Date.now().toString())
        } else {
            setUserAuth(false)
            localStorage.setItem('authToken', "")
            localStorage.setItem('authDate', Date.now().toString())
            setUserInfo( {authDate : Date.now(), authToken: "", phone: `38${inputPhone}`, name: "", city: ""} );
        }

        //console.log(req_res.message.authorized)
        //if (req_res)
        //    setIsPhone(true);

    }

    //{ ( userAuth && userAuth['authToken'])  <div>Авторизовано</div>: <div>НІ</div> }
    return (
        <div className={Styles.loginDiv}>



            { ! isPhone ?
            <div>
                <p>Будь одним з перших!</p>
                <div className={Styles.input_group}>
                    <span className={Styles.input_prefix}>+38</span>

                    <input
                        className={Styles.input}
                        type="number"
                        placeholder="телефон"
                        onKeyUp={(e)=>{ if (e.key === "Enter") auth_btn();  }}
                        onChange={(e)=>setInputPhone(e.target.value)}
                        value={inputPhone}
                    />
                    <button onClick={auth_btn}>
                        Зайти
                    </button>
                </div>

            </div>
                    :
            <div>
                <p>Код доступу вислано в СМС</p>
                <div className={Styles.input_group}>
                    <input
                        className={Styles.inputCode}
                        type="number"
                        placeholder="код з СМС"
                        onKeyUp={(e)=>{ if (e.key === "Enter") auth_code_btn();  }}
                        onChange={(e)=>setInputCode(e.target.value)}
                    />
                    <button onClick={auth_code_btn}>

                        Підтвердити
                    </button>
                </div>
            </div>
            }

        </div>


    );
};