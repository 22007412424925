import axios from "axios";
export class Utils {

    constructor( ) {
    }

    public async requestPost  ( url : string, data : object ) {
        try {
            //let data_raw = await axios({ method: 'post', url: url, params:data });

            let data_raw = await axios.post(url, data);
            //console.log(data_raw)
            //console.log(data_raw.data)
            return data_raw.data;
        } catch (e) {
            console.log(e)
            return false
        }

    }
}