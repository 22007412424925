import React, {useState, useEffect} from 'react';
import Styles from "./Login.module.scss";
//import { IoCalendarSharp } from 'react-icons/io5';
import {IUserInfo} from "../../models/user";
import {Utils} from "../../helpers/utils";
const utils = new Utils();



interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo,
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>
    //handleAdd: (e : React.FormEvent) => void;
}


export const Logout = ({userAuth, setUserAuth, userInfo, setUserInfo} : Props) => {

    const logout_btn = async () => {
            setUserAuth(false)
            //console.log(req_res)
            setUserInfo( {authDate : userInfo.authDate, authToken: "", phone: userInfo.phone, name: "", city: "" } );
            localStorage.setItem('authToken', "")
            //localStorage.setItem('authDate', Date.now().toString())
    }

    //{ ( userAuth && userAuth['authToken'])  <div>Авторизовано</div>: <div>НІ</div> }
    return (
        <div className={Styles.logoutDiv}>

                <p>Вас Авторизовано!</p>
                <div>Сервіс знаходиться у розробці.</div>
                <div>Ми сповістимо Вас коли сервіс запрацює...</div>
                <p></p>
                {/*<button onClick={logout_btn}>*/}
                {/*    Вийти*/}
                {/*</button>*/}


        </div>
    );
};