import { observer } from "mobx-react";
import Styles from "./Soon.module.scss";
import {useEffect} from "react";


export const SoonPage = observer(() => {


    useEffect(() => {
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];
        console.log('Subdomain: ' + subdomain);

    }, []);


    return (
        <div className={Styles.soonDiv}>
            <p className={Styles.soonText}>СКОРО</p>

            <ul>
                Сервіс для тих, хто надає послуги і бажає мати зручний попередній запис клієнтів.

                <li>Онлайн сервіс, де клієнти можуть записаситися до Вас на послуги</li>
                <li>Інтеграція сервісу у Вашу веб-сторінку за декілька кліків</li>
                <li>Персональне URL посилання та QR код для бронювання послуг вашого бізнесу </li>
                <li>Все під контролем - нагадування для працівників та клієнтів про записи</li>
                <li>Керування часом працівників та моніторинг їхнього навантаження</li>

            </ul>
        </div>
    );
});