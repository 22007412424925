import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react";

import {SoonPage} from "./soon/Soon";
import {Login} from "../components/Login/Login";
import {Logout} from "../components/Login/Logout";
import {IUserInfo} from "../models/user";

interface Props{
    userAuth: boolean;
    setUserAuth:  React.Dispatch<React.SetStateAction<boolean>>;
    userInfo: IUserInfo;
    setUserInfo:  React.Dispatch<React.SetStateAction<IUserInfo>>;
    //handleAdd: (e : React.FormEvent) => void;
}
export const RootPage = ({userAuth, setUserAuth, userInfo, setUserInfo} : Props) => {
    return (
        <div>
            <SoonPage/>
            { userAuth ?
                <Logout
                    userAuth={userAuth}
                    setUserAuth={setUserAuth}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                />
                :
                <Login
                    userAuth={userAuth}
                    setUserAuth={setUserAuth}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                />
            }
        </div>
    );
};
