import React, {useEffect, useState} from 'react';
import {Layout} from "./components/Layout/Layout";
import "./style/global.scss";

import {RootPage} from "./pages/RootPage";

import {IUserInfo} from "./models/user";


const App: React.FC = () => {

    useEffect( () => {
        let authDate : number = 0;

        let rawAuthDate: string = localStorage.getItem('authDate') || "";
        if (!isNaN(Number(rawAuthDate)) ) {
            authDate = Number(rawAuthDate);
        }
        setUserInfo( {authDate : authDate, authToken: localStorage.getItem('authToken') || "", phone: localStorage.getItem('inputPhone') || "", name: "", city: "" });

        if (localStorage.getItem('authToken')) setUserAuth(true)
    }, [])

    const [userAuth, setUserAuth] = useState<boolean>(true)
    const [userInfo, setUserInfo] = useState<IUserInfo>({phone: "", authToken: "", authDate: 0, name: "", city: ""})

    return (
        <div className="App">
            <Layout>
                <RootPage
                    userAuth={userAuth}
                    setUserAuth={setUserAuth}
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                />
            </Layout>
        </div>
    );
}

export default App;
